import React from "react";
import styled from "styled-components"

// Components
import Heading1 from "../components/Heading1" 

const About = () => {
  return (
    <Heading1 content="About" />
  );
};

export default About;
